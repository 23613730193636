<template>
  <div class="pages">
    <NavBar class="nav-bar" :iconColor="'#9B9B9B'">
      <div slot="title">闲置区的商品详情</div>
    </NavBar>
    <div class="detail-content">
      <div class="shop-detail">
        <div class="shop-img">
          <img :src="detailsInfo.sell_info.head_img || defaultImg" alt="" />
        </div>
        <div class="shop-info">
          <div class="shop-title">{{ detailsInfo.sell_info.nickname }}</div>
          <div class="time-date">
            <span>{{ detailsInfo.created_date }}发布</span>
            <span> | </span>
            <span>发布于{{ detailsInfo.city_name }}</span>
          </div>
        </div>
        <van-button
          plain
          round
          type="danger"
          size="small"
          class="look-info-btn"
          @click="goToSellerPage"
        >
          卖家信息
        </van-button>
      </div>
      <div class="price">
        <div class="present-price">
          <span>￥</span>
          <span class="integer">{{ toInteger(detailsInfo.price) || 0 }}</span>
          <span>{{ twoFloating(detailsInfo.price) }}</span>
        </div>
        <div class="original-price">
          ￥{{ toInteger(detailsInfo.original_price) || "0"
          }}{{ twoFloating(detailsInfo.original_price) || ".00" }}
        </div>
        <div class="postage" v-if="isDeliver">
          <span>{{ isDeliver }}</span>
        </div>
      </div>
      <div class="details-info">
        <pre>{{ detailsInfo.used_name }}</pre>
        <div v-for="(item, index) in detailsInfo.imgs" :key="index">
          <img
            :src="item"
            alt=""
            class="details-img"
            @click="previewImg(index)"
          />
        </div>
      </div>
      <div class="goods-action">
        <div class="operation" v-if="detailsInfo.is_apy">
          <div class="service" @click="contactSeller">
            <img src="~@/assets/used/service.png" alt="" class="service-img" />
            <span>联系卖家</span>
          </div>
        </div>
        <button
          class="buy-button"
          :class="{ 'button-color': !detailsInfo.is_apy }"
          :disabled="!detailsInfo.is_apy"
          @click="rightAwayBuy"
        >
          立即购买
        </button>
      </div>
      <!-- <van-goods-action class="goods-action">
        <van-goods-action-icon
          icon="friends-o"
          text="联系卖家"
          @click="onClickIcon"
        />
        <van-goods-action-button
          type="danger"
          text="立即购买"
          :disabled="!detailsInfo.is_apy"
          @click="rightAwayBuy"
        />
      </van-goods-action> -->
    </div>
    <van-overlay :show="showLoading">
      <div class="wrapper" @click.stop>
        <div class="block">
          <van-loading type="spinner" size="40" color="#000" vertical>
            拼命加载中...
          </van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { getGoodsDetails } from "@/api/used";
import NavBar from "@/layout//nav-bar//nav-bar";
import tools from "@/common/js/tools";
export default {
  name: "used-details",
  components: {
    NavBar,
  },
  data() {
    return {
      defaultImg: require("@/assets/used/shop_logo.png"),
      detailsInfo: {
        sell_info: {
          head_img: "",
          id: "",
          nickname: "",
        },
      },
      userType: this.$route.query.type,
      goodsClass: {
        id: this.$route.query.goodsId * 1,
        type: 2,
      },
      showLoading: true,
    };
  },
  mounted() {
    this.getGoodsDetails();
    this.isIphone();
  },
  computed: {
    isDeliver() {
      // 运费
      let str = "";
      if (this.detailsInfo.deliver_type * 1 === 1) {
        str = "包邮";
      } else if (this.detailsInfo.deliver_type * 1 === 2) {
        str = "到付";
      } else if (this.detailsInfo.deliver_type * 1 === 3) {
        str = "自提";
      } else {
        return str;
      }
      return str;
    },
  },
  methods: {
    isIphone() {
      let container = document.querySelector(".goods-action");
      let isIphoneX =
        window.devicePixelRatio &&
        window.devicePixelRatio === 3 &&
        window.screen.width === 375 &&
        testUA("iPhone");
      if (isIphoneX) {
        check();
        window.onscroll = check();
      }
      function check() {
        // 处理lib-flexible放大viewport的情况
        let scale = window.innerWidth / window.screen.width;
        // 部分浏览器在滚动页面时会显示/隐藏工具栏，影响视口高度。在有底部工具栏的情况下，不做iPhoneX的fix。100为经验值
        if (window.screen.height - window.innerHeight / scale < 100) {
          container.classList.add("fix-iphonex-bottom");
        } else {
          container.classList.remove("fix-iphonex-bottom");
        }
      }
      function testUA(str) {
        return navigator.userAgent.indexOf(str) > -1;
      }
    },
    contactSeller() {
      this.$router.push({
        name: "information",
        query: {
          uId: this.detailsInfo.m_id,
          groupType: 1,
        },
      });
    },
    rightAwayBuy() {
      // 立即购买
      this.$router.push({
        name: "creation-order",
        query: { goodsId: this.goodsClass.id },
      });
    },
    goToSellerPage() {
      this.$router.push({
        name: "seller-info",
        query: { shopId: this.detailsInfo.sell_info.id },
      });
    },
    async getGoodsDetails() {
      // 获取商品详情
      const res = await getGoodsDetails(this.goodsClass);
      console.log(res);
      if (res.code === 1) {
        this.showLoading = false;
      }
      this.detailsInfo = res.data;
    },
    toInteger(num) {
      // 取整
      let toNum = 0;
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // price = Math.floor(num * 100) / 100;
      // price = String(price).split(".")[1];
      // if (price !== undefined && price.length === 1) {
      //   price = `.${price}0`;
      // } else {
      //   price === undefined ? (price = ".00") : (price = `.${price}`);
      // }
      // return price;
    },
    previewImg(index) {
      ImagePreview({
        images: this.detailsInfo.imgs,
        startPosition: index,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #fff;
}
@inforowheight: 52px; //商铺信息高度
.pages {
  background-color: #fff;
  min-height: 100vh;
  padding-top: 54px;
  box-sizing: border-box;
}
.nav-bar {
  border-bottom: 1px solid rgb(241, 241, 241);
  background-color: #fff;
}
.detail-content {
  padding: 0 12px;
}
.shop-detail {
  display: flex;
  align-items: center;
  padding-top: 26px;
  .shop-img {
    flex-shrink: 0;
    width: @inforowheight;
    height: @inforowheight;
    border-radius: 8px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .shop-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 10px;
    // height: @inforowheight;
    .shop-title {
      font-size: 17px;
      font-weight: 500;
      width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .time-date {
      font-size: 13px;
      color: #aaaaaa;
    }
  }
  .look-info-btn {
    width: 91px;
    height: 34px;
    font-size: 17px;
    flex-shrink: 0;
    margin-left: 50px;
  }
}
.price {
  display: flex;
  align-items: flex-end;
  margin-top: 17px;
  font-size: 16px;
  .present-price {
    margin-right: 10px;
    color: #ed301d;
    .integer {
      font-weight: 500;
      font-size: 23px;
    }
  }
  .original-price {
    text-decoration: line-through;
    color: #d5d5d5;
  }
  .postage {
    width: 38px;
    height: 20px;
    border: 1px solid #ff8c08;
    color: #ff8c08;
    border-radius: 3px;
    text-align: center;
    line-height: 20px;
    margin-left: 16px;
  }
}
.details-img {
  display: block;
  width: 388px;
  height: auto;
  border-radius: 13px;
  margin-bottom: 6px;
}
.details-info {
  font-size: 17px;
  padding-bottom: 54px;
  p {
    word-break: break-all;
  }
}
.goods-action {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0 12px;
  // border-top: 1px solid rgb(241, 241, 241);
  // box-sizing: border-box;
  padding: 0 12px;
  border-top: 1px solid #e4e4e4;
  background-color: #fff;
  height: 63px;
  .operation {
    flex-shrink: 0;
    width: 52px;
    display: flex;
    align-items: center;
    margin-left: 29px;
    margin-right: 41px;
    .service {
      display: flex;
      flex-direction: column;
      align-items: center;
      .service-img {
        display: block;
        width: 31px;
        height: 31px;
      }
      span {
        font-size: 13px;
      }
    }
  }
  .buy-button {
    // width: 267px;
    width: 100%;
    height: 52px;
    background: linear-gradient(to right, #f25757, #ed301d);
    opacity: 1;
    border-radius: 26px;
    font-size: 21px;
    color: #fff;
    line-height: 52px;
    text-align: center;
    border: none;
  }
  .button-color {
    opacity: 0.6;
  }
}
// .van-goods-action {
//   height: 63px;
// }
// .van-goods-action-button {
//   height: 52px;
//   width: 267px;
// }
.fix-iphonex-bottom {
  padding-bottom: 34px;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
